// this file is automatically generated by version.sh script
export const version: Version = {
  version: '2.16.12',
  revision: 'e73d121e8',
  branch: 'HEAD',
  timestamp: '08.01.2025'
};

export interface Version {
  revision: string;
  version: string;
  branch: string;
  timestamp: string;
}
